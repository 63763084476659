import PropTypes from "prop-types";
import { Container, Card, Row, Col } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import Layout from "@layout";
import PhotosDataInit from "../../components/photosdata/photo-page-data.json";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const PhotoGallery = ({ data, location, pageContext }) => {
    const [PhotosData, setJsonData] = useState(PhotosDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a74c74f5091ddcb5e9cd7d"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Photo Gallery" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Photo Gallery"
            />
            <Container>
                <Row style={{ marginLeft: "10%", marginRight: "10%" }}>
                    {PhotosData.map((item, i) => {
                        return (
                            <Col
                                lg={3}
                                md={4}
                                sm={6}
                                key={i}
                                // style={{ paddingRight: "0px" }}
                            >
                                <Link to={item.link}>
                                    <Card
                                        style={{
                                            width: "18rem",
                                            marginBottom: "10px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={item.image}
                                            style={{ height: "160px" }}
                                        />
                                        <Card.Body
                                            style={{
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                            }}
                                        >
                                            <Card.Title
                                                style={{
                                                    fontSize: "15px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {item.title}
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Layout>
    );
};
PhotoGallery.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query PhotoGalleryQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default PhotoGallery;
